import { OrganisationDto, TrackingCodeDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { HubModel, OrganisationModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const organisationApi = createApi({
  reducerPath: 'organisationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.apiHost}/organisation`,
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createOrganisation: builder.query<OrganisationModel, OrganisationDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
      keepUnusedDataFor: 0,
    }),
    getOrganisation: builder.query<OrganisationModel, string>({
      query: (uuid) => ({ url: `${uuid}` }),
      keepUnusedDataFor: 0,
    }),
    updateOrganisation: builder.query<OrganisationModel, { uuid: string; dto: OrganisationDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: `${uuid}`, body: dto }),
    }),
    deleteOrganisation: builder.query<OrganisationModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: `${uuid}` }),
    }),
    updateTrackingCode: builder.query<OrganisationModel, { uuid: string; dto: TrackingCodeDto }>({
      query: ({ uuid, dto }) => ({ method: 'POST', url: `${uuid}/tracking-code`, body: dto }),
    }),
    getPublishList: builder.query<HubModel[], void>({
      query: () => ({
        url: 'publishList',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyCreateOrganisationQuery,
  useGetOrganisationQuery,
  useLazyUpdateOrganisationQuery,
  useLazyDeleteOrganisationQuery,
  useLazyUpdateTrackingCodeQuery,
  useGetPublishListQuery,
} = organisationApi;
